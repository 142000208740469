.bg {
    background-image: url(../img/bg.jpg);
    background-size: cover;
    background-position-y: bottom;
    background-repeat: no-repeat;
}

.credits {
    position: absolute;
    right: 30px;
    bottom: 10px;

    width: 300px;
    text-align: right;
    color: white;
    border-radius: .3rem;

    padding: 12px;
    background: 
        linear-gradient(
            180deg,
            #0e0e1d -10%,
            transparent 120%
        ),
        radial-gradient(
            circle at 240px 80px,
            hsla(0,0%,100%,.3) 0,
            transparent 30%
        ),
        radial-gradient(
            circle at 220px 30px,
            #0e0e1d 0,
            transparent 30%
        ),
        #111;
}